/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// CUSTOM FONTS CUSTOM FONTS CUSTOM FONTS CUSTOM FONTS CUSTOM FONTS
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/Montserrat/montserrat-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Montserrat/montserrat-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Montserrat/montserrat-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-300.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Montserrat/montserrat-v15-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Montserrat/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Montserrat/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Montserrat/montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Montserrat/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Montserrat/montserrat-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Montserrat/montserrat-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Montserrat/montserrat-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-500.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Montserrat/montserrat-v15-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/Montserrat/montserrat-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Montserrat/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Montserrat/montserrat-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-600.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Montserrat/montserrat-v15-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Montserrat/montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Montserrat/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Montserrat/montserrat-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-700.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Montserrat/montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/Montserrat/montserrat-v15-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/Montserrat/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/Montserrat/montserrat-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-800.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/Montserrat/montserrat-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/Montserrat/montserrat-v15-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}

ion-content{
  font-family: Montserrat !important;
}
ion-button{
  font-size: 15px !important;
  font-weight: 700 !important;
  color: #221d1d !important;
  --border-radius: 40px !important;
  font-family: Montserrat !important;
  --background: #fcb814 !important;
}
.rbutton{

}
// CUSTOM FONTS CUSTOM FONTS CUSTOM FONTS CUSTOM FONTS CUSTOM FONTS

//.popover-content ion-select-popover ion-list ion-radio-group ion-item
.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
  //--ion-text-color: #ffff00 !important;
  margin-right: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  //font-size: 140px !important;
  --ion-text-color: #3d4749 !important;
}
ion-select {
  /* Applies to the value and placeholder color */
  //color: #545ca7;

  /* Set a different placeholder color */
  //--placeholder-color: #971e49;

  /* Set full opacity on the placeholder */
  //--placeholder-opacity: 1;
  font-size: 14px !important;
}

ion-input,ion-textarea{
  //color: #3d4749 !important;
  font-size: 14px !important;
}
ion-label.required{
  //color: #ff0000 !important;
}
//ion-item ion-select div{
.select-text{
  color: red  !important;
  --ion-text-color: #ff0000 !important;
}
.popover-content ion-list ion-radio-group ion-item ion-label
{
  font-size: 14px !important;
  //color: green;
}
.popover-content ion-list ion-label
{
  font-size: 14px !important;
  //color: red;
}
.popover-content {
  width: auto !important;
  font-size: 14px !important;
  //margin-right: 150px !important;
  //--ion-text-color: #ff0000 !important;
}
ion-toolbar{
  --ion-text-color: #3d4749 !important;
}
ion-content{
  --ion-text-color: #3d4749 !important;
  font-size: 16px !important;
}
ion-col{
  --ion-text-color: #3d4749 !important;
}
ion-card{
  --ion-text-color: #3d4749 !important;
}
ion-card-content{
  --ion-text-color: #3d4749 !important;
}
ion-grid{
  --ion-text-color: #3d4749 !important;
}

hr{
  //border: 2px solid #a8a8a8;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  //background-image: linear-gradient(to right, rgba(255, 207, 92, 0), rgba(255, 207, 92, 0.75), rgba(255, 207, 92, 0));
}
.def_text_color{
  color: #3d4749 !important;
}
.head1{
  font-size: 21px; font-weight: 600; letter-spacing: .05em;
  color: #3d4749 !important;
}
.head1_success{
  font-size: 21px; font-weight: 600; letter-spacing: .05em;
  color: green !important;
}
.head2{
  font-size: 19px; font-weight: 600;
  color: #3d4749 !important;
}
.head3{
  font-size: 17px; font-weight: 600;
  color: #3d4749 !important;
}
.info{
  font-size: 13px; font-weight: 500;
  color: #808080 !important;
}

.vh_center{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.vh_only_center{
  display: flex;
  align-items: center;
  //justify-content: center;
  height: 100%;
}

.breadcrumbs{
  font-size: 11px;
  color: #3d4749 !important;
  font-weight: 600;
}

.instruction{
  color:#999999;
}
.instruction_white{
  color:#ffffff;
}

.fw100{ font-weight: 100 !important; }
.fw200{ font-weight: 200 !important; }
.fw300{ font-weight: 300 !important; }
.fw400{ font-weight: 400 !important; }
.fw500{ font-weight: 500 !important; }
.fw600{ font-weight: 600 !important; }
.fw700{ font-weight: 700 !important; }
.fw800{ font-weight: 800 !important; }
.fw900{ font-weight: 900 !important; }

.fs10{ font-size: 10px !important; }
.fs11{ font-size: 11px !important; }
.fs12{ font-size: 12px !important; }
.fs13{ font-size: 13px !important; }
.fs14{ font-size: 14px !important; }
.fs15{ font-size: 15px !important; }
.fs16{ font-size: 16px !important; }
.fs17{ font-size: 17px !important; }
.fs18{ font-size: 18px !important; }
.fs19{ font-size: 19px !important; }
.fs20{ font-size: 20px !important; }

.ml0{ margin-left: 0px !important;}
.ml5{ margin-left: 5px !important;}
.ml10{ margin-left: 10px !important;}
.ml15{ margin-left: 15px !important;}
.ml20{ margin-left: 20px !important;}

.mr0{ margin-right: 0px !important;}
.mr5{ margin-right: 5px !important;}
.mr10{ margin-right: 10px !important;}
.mr15{ margin-right: 15px !important;}
.mr20{ margin-right: 20px !important;}

.mb0{ margin-bottom: 0px !important;}
.mb5{ margin-bottom: 5px !important;}
.mb10{ margin-bottom: 10px !important;}
.mb15{ margin-bottom: 15px !important;}
.mb20{ margin-bottom: 20px !important;}

.mt0{ margin-top: 0px !important;}
.mt5{ margin-top: 5px !important;}
.mt10{ margin-top: 10px !important;}
.mt15{ margin-top: 15px !important;}
.mt20{ margin-top: 20px !important;}

.pl0{ padding-left: 0px !important;}
.pl5{ padding-left: 5px !important;}
.pl10{ padding-left: 10px !important;}
.pl15{ padding-left: 15px !important;}
.pl20{ padding-left: 20px !important;}

.pr0{ padding-right: 0px !important;}
.pr5{ padding-right: 5px !important;}
.pr10{ padding-right: 10px !important;}
.pr15{ padding-right: 15px !important;}
.pr20{ padding-right: 20px !important;}

.pb0{ padding-bottom: 0px !important;}
.pb5{ padding-bottom: 5px !important;}
.pb10{ padding-bottom: 10px !important;}
.pb15{ padding-bottom: 15px !important;}
.pb20{ padding-bottom: 20px !important;}

.pt0{ padding-top: 0px !important;}
.pt5{ padding-top: 5px !important;}
.pt10{ padding-top: 10px !important;}
.pt15{ padding-top: 15px !important;}
.pt20{ padding-top: 20px !important;}

.p10{ padding: 10px !important;}

.yellow_border{
  border: 1px solid  #fcb816;
  border-radius: 0.25rem;
}
.yellow_button{
  --background: #fcb816;
  --color:#3d4749;
}
.yellow{
  //--background: #fcb816;
  color:#fcb816 !important;
}
.red_button{
  --background: rgb(235, 68, 90);
  //--color:#3d4749;
  --color:#ffffff;
}

.error-panel{
  background-color: rgb(235, 68, 90);
  //--color:#3d4749;
  color:#ffffff;
}

.wd10{ width:10px !important;}
.wd50{ width:50px !important;}
.wd60{ width:60px !important;}
.wd70{ width:70px !important;}
.wd80{ width:80px !important;}
.wd90{ width:90px !important;}
.wd100{ width:100px !important;}
.wd110{ width:110px !important;}
.wd120{ width:120px !important;}
.wd130{ width:130px !important;}
.wd140{ width:140px !important;}
.wd150{ width:150px !important;}
.wd160{ width:160px !important;}

.default_desc_container{
  display: flex; align-items: center; justify-content: center;
}
.default_desc{
  font-size: 10px !important;
  margin: 5px;
  padding: 2px;
  border-radius: 25px;
  background-color: #46a049 ;
  color: #ffffff;
  width: 115px;
  letter-spacing: 0.05em;
}
.mark_default_desc{
  font-size: 10px !important;
  margin: 5px;
  padding: 2px;
  border-radius: 25px;
  background-color: #fcb816;
  //color: #ffffff;
  width: 115px;
  letter-spacing: 0.05em;
}

.light_gray_panel {
  //background-color: #ffcf5c;
  // width:200px;
   padding: 5px;   margin: 5px;
  background-color: #f4f4f4; border: 1px solid #dcdcdc; border-radius: .25rem;
}

// IONIC css class
.loader-css-class{
  /*--background:#fcb816;
   --spinner-color:#ffffff;
   color:#ffffff;
   font-weight: 500;
   */
  //  --background: #fcb816;
  //    --spinner-color: #000000;
  //   ---- color: #ffffff;
  //   font-weight: 500;
  //   font-family: verdana;

    --background: #fcb816;
    --spinner-color: #ffffff;
    color: #ffffff;
    font-weight: 500;
    font-family: Arial;
    font-size: 18px;

}
// IONIC css class

.fileuploadError-modal{
  //background:blue !important;
}
.fileuploadError-modal .modal-wrapper{
  //background:blue !important;
  height: 50% !important;
}

.fileuploadBlockUser-modal{
  //background:blue !important;
}
.fileuploadBlockUser-modal .modal-wrapper{
  //background:blue !important;
  height: 50% !important;
}

.issuesInfoPage-modal{
  //background:blue !important;
}
.issuesInfoPage-modal .modal-wrapper{
  //background:blue !important;
  height: 80% !important;
  width: 94%;
}

.FinalSubmitSuccessPage-modal .modal-wrapper{
  //background:blue !important;
  height: 63% !important;
}
.FinalSubmitErrorPage-modal .modal-wrapper{
  //background:blue !important;
  height: 42% !important;
}

// change width of pop up dialog for <ion-select>
// Applies a text wrap on ion options
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.alert-wrapper {
  width: 40% !important;
  max-width: 40% !important;
}
// change width of pop up dialog for <ion-select>

// .action-sheet-group {
//   overflow: auto !important;
// }

/*
 * This adds a scroll bar to ionic alerts
 * had to add this for select pop up dialog vertical scroll bar
 */
 .alert-radio-group::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.required:after {
  content:" *";
  color: red;
  font-weight: 900;
  font-size: 24px;
}
.required-small:after {
  content:" *";
  color: red;
  font-weight: 900;
  font-size: 15px;
}
// .custom-select {
//   // position: relative;
//   // font-family: Arial;
// }
// .custom-select select {
//   display: none; /*hide original SELECT element: */
// }

// .select-selected {
//   background-color: DodgerBlue;
// }
// .select-items {
//   padding: 20px;
// }

html {
  scroll-behavior: smooth !important;
}
